/* Iphone 5/SE */
@media only screen and (min-width: 320px) {

  .gif-w {
    width: 100%;
  }
  .red-bar {
    max-height: 4.494rem;
    min-height: 4.494rem;
    height: 4.494rem;
  }
  .gray-bar {
    max-height: 8rem;
    min-height: 8rem;
    height: 8rem;
  }
  .card {
    width: 100%;
    height: 98%;
    margin-top: 4px;
    font-size: small;
  }

  .video-el {
    width: 260px;
    height: 146px;
    margin: 5px;
  }
}
/* Android 1 */
@media only screen and (min-width: 360px) {
  .gif-w {
    width: 100%;
  }
  .red-bar {
    max-height: 5.188rem;
    min-height: 5.188rem;
    height: 5.188rem;
  }
  .gray-bar {
    max-height: 6.625rem;
    min-height: 5.188rem;
    height: 5.188rem;
  }
  .card {
    width: 100%;
    height: 98%;
  }
  .video-el {
    width: 320px;
    height: 206px;
    margin: 5px;
  }
}
/* Iphone 13 mini */
@media only screen and (min-width: 375px) {
  .gif-w {
    width: 100%;
  }
  .red-bar {
    max-height: 6.606rem;
    min-height: 6.606rem;
    height: 6.606rem;
  }
  .gray-bar {
    max-height: 8.481rem;
    min-height: 8.481rem;
    height: 8.481rem;
  }
  .card {
    width: 100%;
    height: 99.5%;
    margin-top: 2px;
  }
  .video-el {
    width: 350px;
    height: 236px;
    margin: 5px;
  }
}
/* Iphone 13 */
@media only screen and (min-width: 390px) {
  .gif-w {
    width: 100%;
  }
  .red-bar {
    max-height: 6.625rem;
    min-height: 6.625rem;
    height: 6.625rem;
  }
  .gray-bar {
    max-height: 8.575rem;
    min-height: 8.575rem;
    height: 8.575rem;
  }
  .card {
    width: 100%;
    height: 99%;
    margin-top: 6px;
  }
}
/* Google Pixel 2 Xl */
@media only screen and (min-width: 411px) {
  .gif-w {
    width: 100%;
  }
  .red-bar {
    max-height: 6.5rem;
    min-height: 6.5rem;
    height: 6.5rem;
  }
  .gray-bar {
    max-height: 8.394rem;
    min-height: 8.394rem;
    height: 8.394rem;
  }
  .card {
    width: 100%;
    height: 98%;
  }
}
/* Iphone 11 pro max  */
@media only screen and (min-width: 414px) {
  .gif-w {
    width: 100%;
  }
  .red-bar {
    max-height: 6.5rem;
    min-height: 6.5rem;
    height: 8.394rem;
  }
  .gray-bar {
    max-height: 8.394rem;
    min-height: 8.394rem;
    height: 8.394rem;
  }
  .card {
    width: 100%;
    height: 98%;
  }
}
/* Iphone 13 pro max  */
@media only screen and (min-width: 428px) {
  .gif-w {
    width: 100%;
  }
  .red-bar {
    max-height: 7.325rem;
    min-height: 7.325rem;
    height: 7.325rem;
  }
  .gray-bar {
    max-height: 9.406rem;
    min-height: 9.406rem;
    height: 9.406rem;
  }
  .card {
    width: 100%;
    height: 98%;
  }
}
/* Ipad Mini */
@media only screen and (min-width: 768px) {
  .gif-w {
    width: 100%;
  }
  .red-bar {
    max-height: 8.063rem;
    min-height: 8.063rem;
    height: 8.063rem;
  }
  .gray-bar {
    max-height: 10.4rem;
    min-height: 10.4rem;
    height: 10.4rem;
  }
  .card {
    width: 100%;
    height: 98%;
  }
}
/* Ipad Pro 11 */
@media only screen and (min-width: 834px) {
  .gif-w {
    width: 100%;
  }
  .red-bar {
    max-height: 9.438rem;
    min-height: 9.438rem;
    height: 9.438rem;
  }
  .gray-bar {
    max-height: 12.125rem;
    min-height: 12.125rem;
    height: 12.125rem;
  }
  .card {
    width: 100%;
    height: 98%;
  }
  .video-el {
    width: 400px;
    height: 270px;
    margin: 5px;
  }
}
/* Ipad Pro 12" PORTRAIT  , appears to work fine now */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .gif-w {
    width: 100%;
  }
  .red-bar {
    height: 10.75rem;
    min-height: 10.75rem;
    max-height: 10.75rem;
  }
  .gray-bar {
    max-height: 13.875rem;
    height: 13.875rem;
    min-height: 13.875rem;
  }
  .card {
    width: 100%;
    height: 98%;
  }
}

/* This is the start of layout 2 devices */

/* Macbook */

@media only screen and (min-width: 1152px) {
  .gif-w {
    width: 59.7%;
  }
  .red-bar {
    min-height: 5.688rem;
    height: 5.688rem;
    max-height: 5.688rem;
  }
  .gray-bar {
    max-height: 7.313rem;
    height: 7.313rem;
    min-height: 7.313rem;
  }
  .card {
    width: 85%;
    height: 98%;
  }

  .video-el {
    width: 460px;
    height: 290px;
    margin: 5px;
  }
}
/* Macbook Pro */
@media (min-height: 700px) and (min-width: 1152px) {
  .gif-w {
    width: 40%;
  }
  .red-bar {
    min-height: 5.688rem;
    height: 5.688rem;
    max-height: 5.688rem;
  }
  .gray-bar {
    max-height: 7.313rem;
    height: 7.313rem;
    min-height: 7.313rem;
  }
  .card {
    width: 85%;
    height: 98%;
  }
}
/* Surface Pro 3 */
@media only screen and (min-width: 1368px) {
  .gif-w {
    max-width: 77%;
    width: 71%;
  }

  .red-bar {
    max-height: 7.375rem;
    height: 7.375rem;
    max-height: 7.375rem;
  }
  .gray-bar {
    max-height: 9.5rem;
    height: 9.5rem;
    max-height: 9.5rem;
  }
  .card {
    width: 79%;
  }
  .video-el {
    width: 520px;
    height: 340px;
    margin: 5px;
  }
}
/* Surface Pro 4 */
@media only screen and (min-width: 1440px) {
  .gif-w {
    width: 65%;
    max-width: 77%;
  }
  .red-bar {
    max-height: 8rem;
    height: 8rem;
    max-height: 8rem;
  }
  .gray-bar {
    max-height: 10.313rem;
    height: 10.313rem;
    max-height: 10.313rem;
  }
  .card {
    width: 75%;
  }
}
/* Dekstop and (min-height: 1024px) */
@media only screen and (min-width: 1440px) {
  .gif-w {
    max-width: 81%;
  }
  .red-bar {
    max-height: 8.313rem;
    height: 8.313rem;
    max-height: 8.313rem;
  }
  .gray-bar {
    max-height: 10.688rem;
    height: 10.688rem;
    max-height: 10.688rem;
  }
  .card {
    width: 75%;
    height: 98%;
  }
}
/* Dekstop Lg */
@media only screen and (min-width: 1980px) {
  .gif-w {
    max-width: 58%;
  }
  .red-bar {
    max-height: 10.313rem;
    height: 10.313rem;
    max-height: 10.313rem;
  }
  .gray-bar {
    max-height: 13.688rem;
    height: 13.688rem;
    max-height: 13.688rem;
  }
  .card {
    width: 75%;
  }
  .video-el {
    width: 700px;
    height: 455px;
    margin: 5px;
  }
}
