// Typography
:root {
  --primary-font: 'DefaultFont'; // Set a default font
}

//@import url('https://fonts.googleapis.com/css2?family=Anta:wght@300;400;600&family=Fuggles:wght@300;400;600&display=swap');

// Use the CSS variable for the font family
body {
  font-family: var(--primary-font, 'Dosis'), sans-serif; // Fallback to 'Dosis' if the variable is not set
}

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// App Stylesheets
@import './styles/custom.scss';
@import './styles/responsive.scss';